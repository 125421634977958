import { createAction } from 'typesafe-actions';

export const INIT_SOCKET = 'INIT SOCKET';
export const initSocket = createAction(INIT_SOCKET)();

export const INITIALIZED_SOCKET = 'INITIALIZED SOCKET';
export const initializedSocket = createAction(INITIALIZED_SOCKET)();

export const CLOSE_SOCKET = 'CLOSE SOCKET';
export const closeSocket = createAction(CLOSE_SOCKET)();

export const SUBSCRIBE_TO_TOPIC = 'SUBSCRIBE TO WORKFLOW TOPIC';
export const subscribeToTopic = createAction(SUBSCRIBE_TO_TOPIC, (topic: string) => ({ topic }))();

export const UNSUBSCRIBE_FROM_TOPIC = 'UNSUBSCRIBE FROM WORKFLOW TOPIC';
export const unsubscribeFromTopic = createAction(UNSUBSCRIBE_FROM_TOPIC, (topic: string) => ({ topic }))();

// export const SEND_MESSAGE = 'SEND MESSAGE';
// export const sendMessage = createAction(SEND_MESSAGE, (topic: string, message: any) =>
//     ({ topic, message })
// )();

export const MESSAGE_RECEIVED = 'MESSAGE RECEIVED';
export const receivedMessage = createAction(MESSAGE_RECEIVED, (topic: string, message: any) => ({ topic, message }))();
