import { ActionType, getType } from 'typesafe-actions';
import { IWorkflowTaskInfo } from '@yonder-mind/ui-core';
import { IProcessStore } from '../../../interfaces/store/workflow';
import * as actions from './process-actions';
import { NotificationState } from '../../../enums';

export type ProcessActions = ActionType<typeof actions>;

const initialState: IProcessStore = {
    allTasks: [],
    tasks: {},
    histories: {},
    comments: {},
    attachments: {},
    permissions: {},
    notifications: {},
    variables: {},
    variablesGrouped: {},
    isUploading: false,
    hasUploadError: false,
    apiErrors: [],
};

function replaceTasksOfProcess(
    tasks: IWorkflowTaskInfo[],
    processInstanceId: string,
    processTasks: IWorkflowTaskInfo[]
) {
    return [...tasks.filter((task) => task.processInstanceId !== processInstanceId), ...processTasks];
}

export default function processReducer(state: IProcessStore = initialState, action: ProcessActions): IProcessStore {
    switch (action.type) {
        case getType(actions.allMyTasksReceived):
            return {
                ...state,
                allTasks: action.payload,
                tasks: action.payload.reduce(
                    (acc: { [processInstanceId: string]: IWorkflowTaskInfo[] }, task: IWorkflowTaskInfo) => ({
                        ...acc,
                        [task.processInstanceId]: [...(acc[task.processInstanceId] || []), task],
                    }),
                    {}
                ),
            };

        case getType(actions.myTasksOfProcessReceived):
            return {
                ...state,
                allTasks: replaceTasksOfProcess(state.allTasks, action.payload.processInstanceId, action.payload.tasks),
                tasks: { [action.payload.processInstanceId]: action.payload.tasks },
            };

        case getType(actions.historiesOfProcessReceived):
            return {
                ...state,
                histories: { ...state.histories, [action.payload.processInstanceId]: action.payload.histories },
            };

        case getType(actions.commentsOfProcessReceived):
            return { ...state, comments: { [action.payload.processInstanceId]: action.payload.comments } };

        case getType(actions.addCommentToProcessConfirmationReceived):
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.payload.processInstanceId]: {
                        ...(state.comments[action.payload.processInstanceId] || {}),
                        [action.payload.comment.taskId]: [
                            ...(
                                (state.comments[action.payload.processInstanceId] || {})[
                                    action.payload.comment.taskId
                                ] || []
                            ).filter((comment) => comment.id !== action.payload.comment.id),
                            action.payload.comment,
                        ],
                    },
                },
            };

        case getType(actions.addedCommentReceived):
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.payload.processInstanceId]: {
                        ...(state.comments[action.payload.processInstanceId] || {}),
                        [action.payload.comment.taskId]: [
                            ...(
                                (state.comments[action.payload.processInstanceId] || {})[
                                    action.payload.comment.taskId
                                ] || []
                            ).filter((comment) => comment.id !== action.payload.comment.id),
                            action.payload.comment,
                        ],
                    },
                },
            };

        case getType(actions.canCommentOnProcessReceived):
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    [action.payload.processInstanceId]: action.payload.canComment,
                },
            };

        case getType(actions.receivedCommentNotificationState):
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.payload.processInstanceId]: action.payload.state,
                },
            };

        case getType(actions.enabledCommentNotificationsOfProcess):
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.payload.processInstanceId]: NotificationState.ENABLED,
                },
            };

        case getType(actions.disabledCommentNotificationsOfProcess):
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.payload.processInstanceId]: NotificationState.DISABLED,
                },
            };

        case getType(actions.attachmentsOfProcessReceived):
            return { ...state, attachments: { [action.payload.processInstanceId]: action.payload.attachments } };

        case getType(actions.uploadAttachment):
            return { ...state, hasUploadError: false, isUploading: true };
        case getType(actions.attachmentUploaded):
            return { ...state, hasUploadError: false, isUploading: false };
        case getType(actions.uploadAttachmentError):
            return { ...state, hasUploadError: true, isUploading: false };

        case getType(actions.receivedVariables):
            return {
                ...state,
                variables: {
                    ...state.variables,
                    [action.payload.processInstanceId]: action.payload.variables,
                },
            };

        case getType(actions.receivedVariablesGrouped):
            return {
                ...state,
                variablesGrouped: {
                    ...state.variablesGrouped,
                    [action.payload.processInstanceId]: action.payload.variables,
                },
            };

        case getType(actions.updatedVariables):
            return {
                ...state,
                variables: {
                    ...state.variables,
                    [action.payload.processInstanceId]: {
                        ...(state.variables[action.payload.processInstanceId] || {}),
                        ...action.payload.updates,
                    },
                },
            };
        case getType(actions.apiErrorReceived):
            return { ...state, apiErrors: [...state.apiErrors, action.payload.error] };
        case getType(actions.apiErrorReset):
            return { ...state, apiErrors: action.payload.errors };

        default:
            return state;
    }
}
