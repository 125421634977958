import { createAction } from 'typesafe-actions';
import {
    IWorkflowTaskInfo,
    IWorkflowHistoricActivity,
    IWorkflowComment,
    IWorkflowActivity,
    IWorkflowAttachment,
} from '@yonder-mind/ui-core';
import { IFormValues } from '../../../interfaces/form';
import { NotificationState } from '../../../enums';
import { IVariablesGrouped } from '../../../interfaces/store/workflow';
import { IApiError } from '../../../interfaces';
import { WorkflowTaskVariant } from '../../../components/Workflow/WorkflowTasks';

// All my tasks

export const REQUEST_ALL_MY_TASKS = 'REQUEST ALL MY TASKS';
export const allMyTasksRequested = createAction(REQUEST_ALL_MY_TASKS)();

export const ALL_MY_TASKS_RECEIVED = 'ALL MY TASKS RECEIVED';
export const allMyTasksReceived = createAction(ALL_MY_TASKS_RECEIVED, (tasks: IWorkflowTaskInfo[]) => tasks)();

// My tasks of process

export const REQUEST_MY_TASKS_OF_PROCESS = 'REQUEST MY TASKS OF PROCESS';
export const myTasksOfProcessRequested = createAction(REQUEST_MY_TASKS_OF_PROCESS, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const MY_TASKS_OF_PROCESS_RECEIVED = 'MY TASKS OF PROCESS RECEIVED';
export const myTasksOfProcessReceived = createAction(
    MY_TASKS_OF_PROCESS_RECEIVED,
    (processInstanceId: string, tasks: IWorkflowTaskInfo[]) => ({ processInstanceId, tasks })
)();

// Get workflow history of process

const REQUEST_HISTORIES_OF_PROCESS = 'REQUEST HISTORIES OF PROCESS';
export const historiesOfProcessRequested = createAction(REQUEST_HISTORIES_OF_PROCESS, (processInstanceId: string) => ({
    processInstanceId,
}))();

const HISTORIES_OF_PROCESS_RECEIVED = 'HISTORIES OF PROCESS RECEIVED';
export const historiesOfProcessReceived = createAction(
    HISTORIES_OF_PROCESS_RECEIVED,
    (processInstanceId: string, histories: IWorkflowHistoricActivity[]) => ({ processInstanceId, histories })
)();

// Can comment on process

const REQUEST_CAN_COMMENT_ON_PROCESS = 'REQUEST CAN COMMENT ON PROCESS';
export const canCommentOnProcessRequested = createAction(
    REQUEST_CAN_COMMENT_ON_PROCESS,
    (processInstanceId: string) => ({ processInstanceId })
)();

const CAN_COMMENT_ON_PROCESS_RECEIVED = 'CAN COMMENT ON PROCESS RECEIVED';
export const canCommentOnProcessReceived = createAction(
    CAN_COMMENT_ON_PROCESS_RECEIVED,
    (processInstanceId: string, canComment: boolean) => ({ processInstanceId, canComment })
)();

// Get comments of process

const REQUEST_COMMENTS_OF_PROCESS = 'REQUEST COMMENTS OF PROCESS';
export const commentsOfProcessRequested = createAction(REQUEST_COMMENTS_OF_PROCESS, (processInstanceId: string) => ({
    processInstanceId,
}))();

const COMMENTS_OF_PROCESS_RECEIVED = 'COMMENTS OF PROCESS RECEIVED';
export const commentsOfProcessReceived = createAction(
    COMMENTS_OF_PROCESS_RECEIVED,
    (processInstanceId: string, comments: { [group: string]: IWorkflowComment[] }) => ({ processInstanceId, comments })
)();

// Get comments of current activity

const REQUEST_COMMENTS_OF_CURRENT_ACTIVITY = 'REQUEST COMMENTS OF CURRENT ACTIVITY';
export const commentsOfCurrentActitvityRequested = createAction(
    REQUEST_COMMENTS_OF_CURRENT_ACTIVITY,
    (processInstanceId: string) => ({ processInstanceId })
)();

const COMMENTS_OF_CURRENT_ACTIVITY_RECEIVED = 'COMMENTS OF CURRENT ACTIVITY RECEIVED';
export const commentsOfCurrentActitvityReceived = createAction(
    COMMENTS_OF_CURRENT_ACTIVITY_RECEIVED,
    (processInstanceId: string, comments: IWorkflowComment[]) => ({ processInstanceId, comments })
)();

// Get comment notification state of a process

const REQUEST_COMMENT_NOTIFICATION_STATE = 'REQUEST COMMENT NOTIFICATION STATE';
export const requestCommentNotificationState = createAction(
    REQUEST_COMMENT_NOTIFICATION_STATE,
    (processInstanceId: string) => ({ processInstanceId })
)();

const RECEIVED_COMMENT_NOTIFICATION_STATE = 'RECEIVED COMMENT NOTIFICATION STATE';
export const receivedCommentNotificationState = createAction(
    RECEIVED_COMMENT_NOTIFICATION_STATE,
    (processInstanceId: string, state: NotificationState) => ({ processInstanceId, state })
)();

// Enable comment notification for the given process

const ENABLE_COMMENT_NOTIFICATIONS_OF_PROCESS = 'ENABLE COMMENT NOTIFICATIONS OF PROCESS';
export const enableCommentNotificationsOfProcess = createAction(
    ENABLE_COMMENT_NOTIFICATIONS_OF_PROCESS,
    (processInstanceId: string) => ({ processInstanceId })
)();

const ENABLED_COMMENT_NOTIFICATIONS_OF_PROCESS = 'ENABLED COMMENT NOTIFICATIONS OF PROCESS';
export const enabledCommentNotificationsOfProcess = createAction(
    ENABLED_COMMENT_NOTIFICATIONS_OF_PROCESS,
    (processInstanceId: string) => ({ processInstanceId })
)();

// Disable comment notification for the given process

const DISABLE_COMMENT_NOTIFICATIONS_OF_PROCESS = 'DISABLE COMMENT NOTIFICATIONS OF PROCESS';
export const disableCommentNotificationsOfProcess = createAction(
    DISABLE_COMMENT_NOTIFICATIONS_OF_PROCESS,
    (processInstanceId: string, comments: IWorkflowComment[]) => ({ processInstanceId, comments })
)();

const DISABLED_COMMENT_NOTIFICATIONS_OF_PROCESS = 'DISABLED COMMENT NOTIFICATIONS OF PROCESS';
export const disabledCommentNotificationsOfProcess = createAction(
    DISABLED_COMMENT_NOTIFICATIONS_OF_PROCESS,
    (processInstanceId: string) => ({ processInstanceId })
)();

// Add comment to process

const REQUEST_ADD_COMMENT_TO_PROCESS = 'REQUEST ADD COMMENT TO PROCESS';
export const addCommentToProcessRequested = createAction(
    REQUEST_ADD_COMMENT_TO_PROCESS,
    (processInstanceId: string, message: string) => ({ processInstanceId, message })
)();

const ADD_COMMENT_TO_PROCESS_RECEIVED = 'ADD COMMENT TO PROCESS RECEIVED';
export const addCommentToProcessConfirmationReceived = createAction(
    ADD_COMMENT_TO_PROCESS_RECEIVED,
    (processInstanceId: string, comment: IWorkflowComment) => ({ processInstanceId, comment })
)();

// Comment was added to process

const COMMENT_WAS_ADDED = 'COMMENT WAS ADDED';
export const addedCommentReceived = createAction(
    COMMENT_WAS_ADDED,
    (processInstanceId: string, comment: IWorkflowComment) => ({ processInstanceId, comment })
)();

// Get attachments of process

const REQUEST_ATTACHMENTS_OF_PROCESS = 'REQUEST ATTACHMENTS OF PROCESS';
export const attachmentsOfProcessRequested = createAction(
    REQUEST_ATTACHMENTS_OF_PROCESS,
    (processInstanceId: string) => ({
        processInstanceId,
    })
)();

const ATTACHMENTS_OF_PROCESS_RECEIVED = 'ATTACHMENTS OF PROCESS RECEIVED';
export const attachmentsOfProcessReceived = createAction(
    ATTACHMENTS_OF_PROCESS_RECEIVED,
    (processInstanceId: string, attachments: IWorkflowAttachment[]) => ({ processInstanceId, attachments })
)();

export const UPLOAD_ATTACHMENT = 'UPLOAD ATTACHMENT';
export const uploadAttachment = createAction(UPLOAD_ATTACHMENT, (processInstanceId: string, attachment: File) => ({
    processInstanceId,
    attachment,
}))();

export const ATTACHMENT_UPLOADED = 'ATTACHMENT UPLOADED';
export const attachmentUploaded = createAction(ATTACHMENT_UPLOADED, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const UPLOAD_ATTACHMENT_ERROR = 'UPLOAD ATTACHMENT ERROR';
export const uploadAttachmentError = createAction(UPLOAD_ATTACHMENT_ERROR, (err: any) => ({ err }))();

export const DELETE_ATTACHMENT = 'DELETE ATTACHMENT';
export const deleteAttachment = createAction(DELETE_ATTACHMENT, (processInstanceId: string, attachmentId: string) => ({
    processInstanceId,
    attachmentId,
}))();

// Complete a task

export const COMPLETE_TASK = 'COMPLETE TASK';
export const completeTask = createAction(
    COMPLETE_TASK,
    (payload: {
        taskId: string;
        processInstanceId: string;
        data: IFormValues;
        documentOid: string;
        variant: WorkflowTaskVariant;
    }) => payload
)();

export const COMPLETED_TASK = 'COMPLETED TASK';
export const taskCompleted = createAction(
    COMPLETED_TASK,
    (taskId: string, processInstanceId: string, data: IFormValues = {}) => ({
        data,
        processInstanceId,
        taskId,
    })
)();

// FETCH VARIABLES

export const REQUEST_PROCESS_VARIABLES = 'REQUEST PROCESS VARIABLES';
export const requestVariables = createAction(REQUEST_PROCESS_VARIABLES, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const RECEIVED_PROCESS_VARIABLES = 'RECEIVED PROCESS VARIABLES';
export const receivedVariables = createAction(
    RECEIVED_PROCESS_VARIABLES,
    (processInstanceId: string, variables: { [name: string]: string }) => ({ processInstanceId, variables })
)();

// FETCH GROUPED VARIABLES

export const REQUEST_PROCESS_VARIABLES_GROUPED = 'REQUEST PROCESS VARIABLES GROUPED';
export const requestVariablesGrouped = createAction(REQUEST_PROCESS_VARIABLES_GROUPED, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const RECEIVED_PROCESS_VARIABLES_GROUPED = 'RECEIVED PROCESS VARIABLES GROUPED';
export const receivedVariablesGrouped = createAction(
    RECEIVED_PROCESS_VARIABLES_GROUPED,
    (processInstanceId: string, variables: IVariablesGrouped) => ({ processInstanceId, variables })
)();

// UPDATE VARIABLES

export const UPDATE_PROCESS_VARIABLES = 'UPDATE PROCESS VARIABLES';
export const updateVariables = createAction(
    UPDATE_PROCESS_VARIABLES,
    (processInstanceId: string, updates: { [name: string]: string }) => ({ processInstanceId, updates })
)();

export const UPDATED_PROCESS_VARIABLES = 'UPDATED PROCESS VARIABLES';
export const updatedVariables = createAction(
    UPDATED_PROCESS_VARIABLES,
    (processInstanceId: string, updates: { [name: string]: string }) => ({ processInstanceId, updates })
)();

// Update process

export const UPDATE_PROCESS = 'UPDATE PROCESS';
export const updateProcess = createAction(UPDATE_PROCESS, (processInstanceId: string) => ({ processInstanceId }))();

export const PROCESS_UPDATE_RECEIVED = 'PROCESS UPDATE RECEIVED';
export const updatedProcess = createAction(
    PROCESS_UPDATE_RECEIVED,
    (processInstanceId: string, activity: IWorkflowActivity) => ({ activity, processInstanceId })
)();

export const API_ERROR_RECEIVED = 'API ERROR RECEIVED';
export const apiErrorReceived = createAction(API_ERROR_RECEIVED, (error: IApiError) => ({ error }))();

export const API_ERROR_RESET = 'API ERROR RESET';
export const apiErrorReset = createAction(API_ERROR_RESET, (errors: IApiError[]) => ({ errors }))();
