import { ActionType, getType } from 'typesafe-actions';
import { IRevisionStore } from '../../../interfaces/store/workflow';
import * as actions from './revision-actions';

export type RevisionActions = ActionType<typeof actions>;

const initialState: IRevisionStore = {
    allRevisions: [],
    documentRevisionHistories: {},
    revisionHistorics: {},
    allowedToStartRevisionWorkflow: false,
    apiErrors: [],
    isLoading: false,
};

export default function revisionReducer(state: IRevisionStore = initialState, action: RevisionActions): IRevisionStore {
    switch (action.type) {
        case getType(actions.allRevisionsRequested):
            return { ...state, isLoading: true };
        case getType(actions.allRevisionsReceived):
            return { ...state, allRevisions: action.payload, isLoading: false };

        case getType(actions.revisionHistoryOfDocumentReceived):
            return {
                ...state,
                documentRevisionHistories: {
                    ...state.documentRevisionHistories,
                    [action.payload.contextVersionOid]: action.payload.history,
                },
            };

        case getType(actions.revisionHistoricReceived):
            return {
                ...state,
                revisionHistorics: {
                    ...state.revisionHistorics,
                    [action.payload.documentId]: action.payload.historic,
                },
            };

        case getType(actions.revisionForDocumentReceived):
            return {
                ...state,
                allRevisions: state.allRevisions.map((revision) =>
                    revision.processInstanceId === action.payload.processInstanceId ? action.payload : revision
                ),
            };

        case getType(actions.revisionUpdatesReceived):
            return {
                ...state,
                allRevisions: [
                    ...state.allRevisions.filter(
                        (revision) => revision.processInstanceId !== action.payload.processInstanceId
                    ),
                    ...(action.payload.activity ? [action.payload.activity] : []),
                ],
            };

        case getType(actions.receivedNewRevision):
            return {
                ...state,
                allRevisions: [
                    ...state.allRevisions.filter((revision) => revision.id !== action.payload.revision.id),
                    action.payload.revision,
                ],
            };

        case getType(actions.revisionVariableUpdatesReceived):
            return {
                ...state,
                allRevisions: state.allRevisions.map((revision) => ({
                    ...revision,
                    variables: {
                        ...revision.variables,
                        ...action.payload.updates,
                    },
                })),
            };

        case getType(actions.newRevisionFormFieldsReceived):
            return {
                ...state,
                startingFormFields: action.payload,
                allowedToStartRevisionWorkflow: true,
            };
        case getType(actions.apiErrorReceived):
            return { ...state, apiErrors: [...state.apiErrors, action.payload.error] };
        case getType(actions.apiErrorReset):
            return { ...state, apiErrors: action.payload.errors };

        default:
            return state;
    }
}
