import { createAction } from 'typesafe-actions';
import {
    IWorkflowActivity,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
} from '@yonder-mind/ui-core';
import { IApiError } from '../../../interfaces';

// All revisions

export const REQUEST_ALL_REVISIONS = 'REQUEST ALL REVISIONS';
export const allRevisionsRequested = createAction(REQUEST_ALL_REVISIONS)();

export const ALL_REVISIONS_RECEIVED = 'ALL REVISIONS RECEIVED';
export const allRevisionsReceived = createAction(ALL_REVISIONS_RECEIVED, (tasks: IWorkflowActivity[]) => tasks)();

// All revisions of a particular document

export const REQUEST_REVISION_HISTORY_OF_DOCUMENT = 'REQUEST REVISION HISTORY OF DOCUMENT';
export const revisionHistoryOfDocumentRequested = createAction(
    REQUEST_REVISION_HISTORY_OF_DOCUMENT,
    (contextVersionOid: string) => contextVersionOid
)();

export const REVISION_HISTORY_OF_DOCUMENT_RECEIVED = 'REVISION HISTORY OF DOCUMENT RECEIVED';
export const revisionHistoryOfDocumentReceived = createAction(
    REVISION_HISTORY_OF_DOCUMENT_RECEIVED,
    (contextVersionOid: string, history: IWorkflowHistoricActivity[]) => ({ contextVersionOid, history })
)();

export const REQUEST_REVISION_FOR_DOCUMENT = 'REQUEST REVISION FOR DOCUMENT';
export const revisionForDocumentRequested = createAction(
    REQUEST_REVISION_FOR_DOCUMENT,
    (documentOid: string) => documentOid
)();
export const REVISION_FOR_DOCUMENT_RECEIVED = 'REVISION FOR DOCUMENT RECEIVED';
export const revisionForDocumentReceived = createAction(
    REVISION_FOR_DOCUMENT_RECEIVED,
    (revision: IWorkflowActivity) => revision
)();

export const REQUEST_REVISION_HISTORIC = 'REQUEST REVISION HISTORIC';
export const revisionHistoricRequested = createAction(
    REQUEST_REVISION_HISTORIC,
    (documentId: string, state?: 'FINISHED' | 'ALL' | 'UNFINISHED') => ({ documentId, state })
)();
export const REVISION_HISTORIC_RECEIVED = 'REVISION HISTORIC RECEIVED';
export const revisionHistoricReceived = createAction(
    REVISION_HISTORIC_RECEIVED,
    (documentId: string, historic: IWorkflowRevisionHistoric) => ({ documentId, historic })
)();

// Form fields to start a new revision

export const REQUEST_START_NEW_REVISION_FORM = 'REQUEST START NEW REVISION FORM FIELDS';
export const newRevisionFormFieldsRequested = createAction(
    REQUEST_START_NEW_REVISION_FORM,
    (contextType: string, contextSubType: string) => ({
        contextType,
        contextSubType,
    })
)();

export const START_NEW_REVISION_FORM_RECEIVED = 'START NEW REVISION FORM FIELD RECEIVED';
export const newRevisionFormFieldsReceived = createAction(
    START_NEW_REVISION_FORM_RECEIVED,
    (fields: IWorkflowFormField[]) => fields
)();

// Form fields to start a new revision

export const REQUEST_START_NEW_REVISION = 'REQUEST START NEW REVISION';
export const startNewRevisionRequested = createAction(REQUEST_START_NEW_REVISION, (contextType: string, data: any) => ({
    contextType,
    data,
}))();

export const START_NEW_REVISION_CONFIRMATION_RECEIVED = 'START NEW REVISION CONFIRMATION RECEIVED';
export const startNewRevisionConfirmationReceived = createAction(
    START_NEW_REVISION_CONFIRMATION_RECEIVED,
    (revision: IWorkflowActivity[]) => revision
)();

// Received new revision

export const NEW_REVISION_RECEIVED = 'NEW REVISION RECEIVED';
export const receivedNewRevision = createAction(NEW_REVISION_RECEIVED, (revision: IWorkflowActivity) => ({
    revision,
}))();

// Update revision variables

export const REVISION_VARIABLE_UPDATES_RECEIVED = 'CHANGE REQUEST VARIABLE UPDATES RECEIVED';
export const revisionVariableUpdatesReceived = createAction(
    REVISION_VARIABLE_UPDATES_RECEIVED,
    (processInstanceId: string, updates: { [id: string]: string }) => ({ processInstanceId, updates })
)();

// Update revision

export const REVISION_UPDATES_RECEIVED = 'REVISION UPDATE RECEIVED';
export const revisionUpdatesReceived = createAction(
    REVISION_UPDATES_RECEIVED,
    (processInstanceId: string, activity: IWorkflowActivity | null) => ({ activity, processInstanceId })
)();

export const API_ERROR_RECEIVED = 'API ERROR RECEIVED';
export const apiErrorReceived = createAction(API_ERROR_RECEIVED, (error: IApiError) => ({ error }))();

export const API_ERROR_RESET = 'API ERROR RESET';
export const apiErrorReset = createAction(API_ERROR_RESET, (errors: IApiError[]) => ({ errors }))();
