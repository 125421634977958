import { IWorkflowActivity, IWorkflowFormField, WorkflowUtils } from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

const getRevisions = (state: IWebApplicationStore): IWorkflowActivity[] => state.workflow.revision.allRevisions || [];

const getNewRevisionsFormFields = (state: IWebApplicationStore): IWorkflowFormField[] =>
    state.workflow.revision.startingFormFields || [];

const getRevisionsByContextVersion = (
    state: IWebApplicationStore
): { [contextVersionOid: string]: IWorkflowActivity | undefined } =>
    state.workflow.revision.allRevisions.reduce((acc, revision) => {
        if (revision) {
            return {
                ...acc,
                [WorkflowUtils.getContextVersionToEditOid(revision.variables)]: revision,
            };
        }

        return acc;
    }, {});

const getRevisionsByProcessId = (
    state: IWebApplicationStore
): { [processInstanceId: string]: IWorkflowActivity | undefined } =>
    getRevisions(state).reduce(
        (acc, revision) => ({
            ...acc,
            [revision.processInstanceId]: revision,
        }),
        {}
    );

export const revisionSelector = {
    getNewRevisionsFormFields,
    getRevisionsByContextVersion,
    getRevisionsByProcessId,
};
