import {
    type ComplianceInfo,
    ContextInfo,
    ContextType,
    Factory as RestFactory,
    IApiContentStyle,
    IApiContextVersion,
    IApiContextVersionChange,
    IApiContextVersionMetadata,
    IApiDocumentExport,
    IApiModule,
    IApiModuleVersion,
    IApiModuleVersionChange,
    IApiModuleVersionSearchResult,
    IApiPagedList,
    IApiPrintProfileData,
    IApiScoreDoc,
    IApiSearchFilter,
    IApiTagDomain,
    IApiTenantSettings,
    IDatabaseApi,
    IMaintenanceStatus,
    IUser,
    Rest,
    UrlSchemeUtils,
} from '@yonder-mind/ui-core';
import { ApiConstants } from '../utils';
import { saveAs } from 'file-saver';

// TODO: add tests for this in ui-web
export class RestDbApi implements IDatabaseApi {
    async getAllContextVersions(): Promise<IApiContextVersion[]> {
        const response = await Rest.getRequest(ApiConstants.getAllContextVersions().toString());
        return response.json();
    }

    async getContextTitleBasicInfo({ minRevisions }: { minRevisions?: number }): Promise<ContextInfo[]> {
        const response = await Rest.getRequest(ApiConstants.getContextTitleBasicInfo({ minRevisions }).toString());
        return response.json();
    }

    async getAllContextVersionsMetadata(): Promise<IApiContextVersionMetadata[]> {
        const response = await Rest.getRequest(ApiConstants.getAllContextVersionsMetadata().toString());
        return response.json();
    }

    async getAllContentStyles(): Promise<IApiContentStyle[]> {
        const response = await Rest.getRequest(ApiConstants.getAllContentStyles().toString());
        return response.json();
    }

    async getAllTagDomainsForUser(): Promise<IApiTagDomain[]> {
        const response = await Rest.getRequest(ApiConstants.getAllTagDomainsForUser().toString());
        return response.json();
    }

    async getModuleVersionContent(moduleVersionOid: string): Promise<Record<string, unknown>> {
        const response = await Rest.getRequest(ApiConstants.getModuleVersionContent(moduleVersionOid).toString());
        return response.json();
    }

    async getContextVersion(contextVersionOid: string): Promise<IApiContextVersion> {
        const response = await Rest.getRequest(ApiConstants.getContextVersion(contextVersionOid).toString());
        return response.json();
    }

    async getContextVersionFilter(contextVersionOid: string, selectedTags: string[]): Promise<string[]> {
        const response = await Rest.getRequest(
            ApiConstants.getContextVersionFilter(contextVersionOid, selectedTags).toString()
        );
        return response.json();
    }

    async getContentFlow(
        contextVersionOid: string,
        contentFlowIndex: number,
        searchTerm?: string
    ): Promise<IApiModule[]> {
        const response = await Rest.getRequest(
            ApiConstants.getContentFlow(contextVersionOid, contentFlowIndex, searchTerm).toString()
        );
        const data = await response.json();
        return RestFactory.mapModules(data, (content) => UrlSchemeUtils.replaceUrlScheme(content, ''));
    }

    async getContextVersionsByContextOid(contextOid: string): Promise<IApiContextVersion[]> {
        const response = await Rest.getRequest(ApiConstants.getContextVersionsByContextOid(contextOid).toString());
        return response.json();
    }

    async getContextVersionsMetadataByContextOid(contextOid: string): Promise<IApiContextVersionMetadata[]> {
        const response = await Rest.getRequest(
            ApiConstants.getContextVersionsMetadataByContextOid(contextOid).toString()
        );
        return response.json();
    }

    async getChanges(
        moduleVersionOids?: string[],
        contextVersionOids?: string[]
    ): Promise<IApiContextVersionChange[] | IApiModuleVersionChange[]> {
        const payload = {
            moduleVersionOids,
            contextVersionOids,
        };
        const response = await Rest.postRequest(ApiConstants.getChanges().toString(), payload);
        return response.json();
    }

    async getSearchFilters(query: string): Promise<IApiSearchFilter> {
        const response = await Rest.getRequest(ApiConstants.getSearchFilters(query).toString());
        return response.json();
    }

    async getSearchResult(
        query: string,
        page: number,
        pageSize: number,
        selectedTagOids: string[],
        selectedContextOids: string[],
        selectedSort: string,
        nextPageOffset?: IApiScoreDoc
    ): Promise<IApiPagedList<IApiModuleVersionSearchResult>> {
        const response = await Rest.getRequest(
            ApiConstants.search(
                query,
                page,
                pageSize,
                selectedTagOids,
                selectedContextOids,
                selectedSort,
                nextPageOffset
            ).toString()
        );
        return response.json();
    }

    async getAllModuleVersionByModuleOid(moduleOid: string): Promise<IApiModuleVersion[]> {
        const response = await Rest.getRequest(ApiConstants.getAllModuleVersionsByModuleOid(moduleOid).toString());
        const data = await response.json();
        return RestFactory.mapModuleVersion(data, (content) => UrlSchemeUtils.replaceUrlScheme(content, ''));
    }

    async getAllModuleVersionByModuleVersionOid(moduleVersionOid: string): Promise<IApiModuleVersion[]> {
        const response = await Rest.getRequest(
            ApiConstants.getAllModuleVersionsByModuleVersionOid(moduleVersionOid).toString()
        );
        const data = await response.json();
        return RestFactory.mapModuleVersion(data, (content) => UrlSchemeUtils.replaceUrlScheme(content, ''));
    }

    // Get print profile
    async getPrintProfile(contextOid: string): Promise<IApiPrintProfileData> {
        const response = await Rest.getRequest(ApiConstants.getPrintProfile(contextOid).toString());
        return response.json();
    }

    // Start with document export job
    async printDocument(
        contextVersionOid: string,
        filters: string[],
        contentFlowIndex?: number
    ): Promise<IApiDocumentExport> {
        const response = await Rest.postRequest(ApiConstants.printDocument(contextVersionOid).toString(), {
            contextVersionOid,
            filters,
            contentFlowIndex,
        });
        return response.json();
    }

    async getExportJobs(exportJobId: number): Promise<IApiDocumentExport> {
        const response = await Rest.getRequest(ApiConstants.getExportJobs(exportJobId).toString());
        return response.json();
    }

    async downloadExportJob(exportJobId: number, documentInfo: string): Promise<any> {
        const response = await Rest.getRequest(ApiConstants.downloadExportJob(exportJobId).toString());
        const blob: Blob = await response.blob();
        const contentDisposition = response.headers?.get('Content-Disposition');
        const filename = contentDisposition?.split('filename=')[1]?.replace(/"/g, '');
        const extension = filename?.split('.')?.pop();
        saveAs(blob, `${documentInfo}.${extension || '.pdf'}`);
        //Temporary return url so that saga isnt broken
        return response.url;
    }

    async getMaintenanceStatus(): Promise<IMaintenanceStatus> {
        const response = await Rest.getRequest(ApiConstants.getMaintenanceStatus().toString());
        return response.json();
    }

    async getModuleComplianceInfo(moduleOid: string): Promise<ComplianceInfo[] | null> {
        const response = await Rest.getRequest(ApiConstants.getModuleComplianceInfo(moduleOid).toString());
        return response.json();
    }

    async getModuleByModuleOid(moduleOid: string): Promise<IApiModule> {
        const response = await Rest.getRequest(ApiConstants.getModuleByModuleOid(moduleOid).toString());
        return response.json();
    }

    async getUsers(): Promise<IUser[]> {
        const response = await Rest.getRequest(ApiConstants.getUsers().toString());
        return response.json();
    }

    async getAllTagDomainsForFileDrop(): Promise<IApiTagDomain[]> {
        const response = await Rest.getRequest(ApiConstants.getAllTagDomainsForFileDrop().toString());
        return response.json();
    }

    async getTenantSettings(): Promise<IApiTenantSettings> {
        const response = await Rest.getRequest(ApiConstants.getTenantSettings().toString());
        return response.json();
    }

    async getContextTypes(): Promise<ContextType[]> {
        const response = await Rest.getRequest(ApiConstants.getContextTypes().toString());
        return response.json();
    }
}
