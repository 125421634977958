import { Grid, TextField, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import {
    authSelector,
    complianceInfoActions,
    Empty,
    FabButton,
    filesActions,
    FullContentEditor,
    IApiContextVersion,
    IApiLink,
    IApiModuleVersion,
    IApplicationStore,
    IWorkflowActivity,
    IWorkflowCrInEditInfo,
    IWorkflowHistoricActivity,
    LockSource,
    modulesSelector,
    ModuleVersionContent,
    Spinner,
    UrlSchemeUtils,
    useClipboard,
    WorkflowUtils,
    YonderButton,
    YonderDialog,
} from '@yonder-mind/ui-core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CRName } from '../../../../config';
import { useWorkflow } from '../../../../context';
import {
    IWebApplicationStore,
    IWorkflowCrEdit,
    IWorkflowCrEditContent,
    IWorkflowCrEditTitle,
} from '../../../../interfaces';
import { ApiConstants } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { crActions, importJobActions } from '../../../../store';
import { ComplianceInfoBox } from './ComplianceInfoBox/ComplianceInfoBox';
import { ComplianceInfoModal } from './ComplianceInfoBox/ComplianceInfoModal';
import { useSnackbar } from 'notistack';
import { adjustEditorHeight, adjustEditorHeightOnResizeListenerHandler } from './AdjustEditorHeight';

interface IProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion;
    currentPage: number;
    setIsEditing: (isEditing: boolean) => void;
    setSplitViewDisabled: (disabled: boolean) => void;
    diffingActive: boolean;
    onUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const DraftView: React.FC<IProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    currentPage,
    setIsEditing,
    setSplitViewDisabled,
    diffingActive,
    onUnsavedChanges,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { getCREdit, actions, draftSaved, editDraftSuccess, draftContentError, finishedLoadingDraftContent } =
        useWorkflow('cr');
    const { allRevisions } = useWorkflow('revision');
    const { moduleLinkTargets, requestModuleLinkTargets } = useClipboard();
    const dispatch = useDispatch();

    const userInfo = useSelector(authSelector.userInfo);
    const [isComplianceInfoModalOpen, setIsComplianceInfoModalOpen] = useState(false);

    const froalaEditorRef = useRef(null);

    useEffect(() => {
        if (crModuleVersion) {
            dispatch(complianceInfoActions.complianceInfoRequested(crModuleVersion.moduleOid));
        }
    }, [crModuleVersion]);

    useEffect(() => {
        if (fileList.length === 0) {
            dispatch(filesActions.fileListRequested());
        }
    }, []);

    const complianceInfo = useSelector((store: IWebApplicationStore) => store.complianceInfo);
    const fileList = useSelector((store: IWebApplicationStore) => store.files.fileList);
    const isDeleteCR = changeRequest.activityName == CRName.DELETE_MODULE;
    const isEditCR = ([CRName.EDIT_TITLE, CRName.EDIT_CONTENT, CRName.ADD_MODULE] as string[]).includes(
        changeRequest.activityName
    );
    const revision =
        allRevisions.find(
            (rev) =>
                WorkflowUtils.getContextOid(rev.variables) === document.contextOid &&
                !rev.variables.IS_TEMPORARY_REVISION
        ) || ({} as any);
    const { editmode, editcontentrole } = revision.variables || ({} as any);
    let canEditContentRole = false;
    if (editcontentrole) {
        const editContentRoles = editcontentrole.replace(/\s/g, '').split(',');
        for (const role of editContentRoles) {
            if (userInfo.roles.includes(role)) {
                canEditContentRole = true;
                break;
            }
        }
    }
    const hasRevision = editmode && (editmode as string).toLowerCase() === 'credit' && canEditContentRole;
    const { allowedit } = changeRequest.variables || ({} as any);
    const hasChangeRequest = allowedit && (allowedit as string).toLowerCase() === 'true';
    const canEditCR = isEditCR && hasRevision && hasChangeRequest;
    const [crEditTitle, setCREditTitle] = useState<IWorkflowCrEditTitle | undefined>(undefined);
    const [crEditContent, setCREditContent] = useState<IWorkflowCrEditContent | undefined>(undefined);
    const [crInEditLockInfo, setCRInEditLockInfo] = useState<IWorkflowCrInEditInfo | undefined>({
        username: 'initial',
        lockSource: 'initial',
    });
    const [isEditingCRTitle, setEditingCRTitle] = useState(false);
    const [isEditingCRContent, setEditingCRContent] = useState(false);
    const [isContentLoaded, setContentLoaded] = useState(false);
    const [isDraftVersionAvailable, setIsDraftVersionAvailable] = useState(true);

    const importJobLockData = useSelector((store: IWebApplicationStore) => store.import.importJobLock);

    useEffect(() => {
        setIsEditing(isEditingCRTitle || isEditingCRContent);
    }, [isEditingCRTitle, isEditingCRContent]);

    useEffect(() => {
        return () => {
            closeCREditContentEditor();
        };
    }, []);

    useEffect(() => {
        const importJobLock = importJobLockData?.[document?.oid];

        if (importJobLock && importJobLock.data) {
            const importJobLockUsername = importJobLock.data.username;
            const importJobLockSource = importJobLock.data.lockSource;
            if (
                importJobLockUsername !== userInfo.preferred_username ||
                (importJobLockUsername === userInfo.preferred_username && importJobLockSource === LockSource.ADMIN_UI)
            ) {
                setCRInEditLockInfo({ username: importJobLockUsername, lockSource: importJobLockSource });
            }
        } else {
            setCRInEditLockInfo(undefined);
        }
    }, [importJobLockData]);

    const nextModule = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextVersionOid(state, crModuleVersion?.moduleOid, document?.oid)
    );

    // Active processes have the field 'activityName' - but finished processes won't have that field, instead we use the field 'processDefinitionName'
    const isChangeRequestType = (cr: IWorkflowActivity | IWorkflowHistoricActivity, expectedType: string) => {
        return (
            (cr.activityName && cr.activityName === expectedType) ||
            (cr.processDefinitionName && cr.processDefinitionName === expectedType)
        );
    };

    const checkImportJobLock = (crEdit: IWorkflowCrEdit, isEditAvailable: boolean) => {
        if (crEdit && (crEdit.title || crEdit.content) && (isEditingCRTitle || isEditingCRContent)) {
            if (!crEdit.importJobLock) {
                setCREditTitle(crEdit.title);
                setCREditContent(crEdit.content);
                !isEditAvailable && setEditingCRContent(false);

                const { importJobLock } = crEdit.title || crEdit.content;
                if (importJobLock) {
                    const { username, lockSource } = importJobLock;
                    if (username === userInfo.preferred_username && lockSource !== LockSource.ADMIN_UI) {
                        !isEditAvailable && setCRInEditLockInfo({ username, lockSource });
                    } else {
                        setEditingCRTitle(false);
                        setEditingCRContent(false);
                        setCRInEditLockInfo({ username, lockSource });
                    }
                }
            }
        }
    };

    useEffect(() => {
        adjustEditorHeight();
    }, [window.document.querySelector('.fr-toolbar')?.clientHeight]);

    useEffect(adjustEditorHeightOnResizeListenerHandler, []);

    useEffect(() => {
        // Set initially to loaded
        setContentLoaded(true);

        const crEdit = getCREdit(changeRequest.processInstanceId);
        // CR data not loaded and CC or CN
        if (
            !crEdit &&
            (isChangeRequestType(changeRequest, CRName.EDIT_CONTENT) ||
                isChangeRequestType(changeRequest, CRName.ADD_MODULE))
        ) {
            setContentLoaded(false);
            if (
                changeRequest.variables.CHANGE_REQUEST_EDIT_MODULE_VERSION_ID ||
                changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID
            ) {
                setIsDraftVersionAvailable(true);
                actions.requestPeekCRContent(
                    changeRequest.processInstanceId,
                    WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                    WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
                );
            } else {
                setIsDraftVersionAvailable(false);
                setContentLoaded(true);
            }
        }
        // CR data not loaded and CT
        if (!crEdit && isChangeRequestType(changeRequest, CRName.EDIT_TITLE) && crModuleVersion) {
            setContentLoaded(true);
            actions.requestPeekCRTitle(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                crModuleVersion.moduleOid
            );
        }
        // CR data not loaded for CD
        if (!crEdit && isChangeRequestType(changeRequest, CRName.DELETE_MODULE)) {
            setContentLoaded(true);
        }
        // CR data loaded and CC or CN
        if (
            crEdit &&
            (isChangeRequestType(changeRequest, CRName.EDIT_CONTENT) ||
                isChangeRequestType(changeRequest, CRName.ADD_MODULE))
        ) {
            setCREditContent(crEdit.content);
            setContentLoaded(true);
        }
        // CR data loaded and CT or CD
        if (
            crEdit &&
            (isChangeRequestType(changeRequest, CRName.EDIT_CONTENT) ||
                isChangeRequestType(changeRequest, CRName.ADD_MODULE))
        ) {
            setContentLoaded(true);
        }
        checkImportJobLock(crEdit, true);
    }, [getCREdit(changeRequest.processInstanceId)]);

    useEffect(() => {
        const crEdit = getCREdit(changeRequest.processInstanceId);
        if (isEditingCRContent && crEditContent?.moduleVersionContent === undefined) {
            checkImportJobLock(crEdit, false);
        }
    }, [isEditingCRContent, crEditContent, getCREdit(changeRequest.processInstanceId)]);

    const startEditingCR = () => {
        if (isChangeRequestType(changeRequest, CRName.EDIT_TITLE)) {
            actions.requestCRTitle(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                crModuleVersion.moduleOid
            );
            setEditingCRTitle(true);
        } else if (
            isChangeRequestType(changeRequest, CRName.EDIT_CONTENT) ||
            isChangeRequestType(changeRequest, CRName.ADD_MODULE)
        ) {
            setContentLoaded(false);
            actions.requestCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
            );
            setEditingCRContent(true);
            setSplitViewDisabled(true);
        }
    };

    useEffect(() => {
        if (draftSaved) {
            setSplitViewDisabled(false);
            setEditingCRContent(false);
            dispatch(crActions.contextVersionImportJobHasChangesReceived(true));
        }
    }, [draftSaved]);

    useEffect(() => {
        if (editDraftSuccess) {
            setContentLoaded(true);
            setSplitViewDisabled(false);
            setEditingCRContent(false);
        }
    }, [editDraftSuccess]);

    useEffect(() => {
        if (finishedLoadingDraftContent && !isContentLoaded) {
            setContentLoaded(true);
        }
    }, [finishedLoadingDraftContent]);

    useEffect(() => {
        const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);
        const importJobLockSource = importJobLockData?.[contextVersionToEditOid]?.data?.lockSource;
        const importJobLockUsername = importJobLockData?.[contextVersionToEditOid]?.data?.username;
        if (importJobLockSource === LockSource.ADMIN_UI && importJobLockUsername !== userInfo.preferred_username) {
            setContentLoaded(false);
            actions.requestCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
            );
            setSplitViewDisabled(true);
        }
    }, [importJobLockData]);

    const closeCREditTitleDialog = () => {
        setEditingCRTitle(false);
        setCREditTitle(undefined);

        actions.cancelEditCRTitle(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            crModuleVersion.moduleOid
        );
    };
    const saveCREditTitle = () => {
        setEditingCRTitle(false);
        setCREditTitle(undefined);

        if (crEditTitle) {
            actions.saveCRTitle(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                changeRequest.variables.CHANGE_REQUEST_MODULE_ID,
                crEditTitle
            );
        }
    };
    const handleCREditTitleChange = (e: React.ChangeEvent<any>) => {
        if (crEditTitle) {
            setCREditTitle({
                ...crEditTitle,
                moduleVersionTitle: e.target.value,
            });
        }
    };

    const closeCREditContentEditor = () => {
        actions.resetDraftErrors();
        setEditingCRContent(false);
        setSplitViewDisabled(false);
        onUnsavedChanges(false);

        actions.cancelEditCRContent(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
        );
    };

    const saveCREditContent = () => {
        const editor = froalaEditorRef.current.editor;
        let moduleVersionContentToSave = crEditContent?.moduleVersionContent;
        if (editor.codeView.isActive()) {
            // need to toggle code view to get the latest content
            // https://github.com/froala/wysiwyg-editor/issues/113
            editor.codeView.toggle();
            moduleVersionContentToSave = editor.html.get();
        }

        const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);
        dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));
        if (crEditContent && canEditCR) {
            actions.saveCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables),
                { ...crEditContent, moduleVersionContent: moduleVersionContentToSave }
            );
        } else {
            setEditingCRContent(false);
            setSplitViewDisabled(false);
            actions.cancelEditCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
            );
        }
        onUnsavedChanges(false);
    };

    const handleCREditContentChange = (change: string) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                moduleVersionContent: change,
            });
            const crEdit = getCREdit(changeRequest.processInstanceId);
            onUnsavedChanges(crEdit.content !== crEditContent);
        }
    };
    const handleLinkCreate = (link: IApiLink) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: [...(crEditContent.links || []), link],
            });
        }
    };
    const handleLinkUpdate = (link: IApiLink) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: [...(crEditContent.links || []).filter((l) => l.oid !== link.oid), link],
            });
        }
    };
    const handleLinkDelete = (linkOid: string) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: (crEditContent.links || []).filter((l) => l.oid !== linkOid),
            });
        }
    };

    const setContent = () => {
        if (!crEditContent) {
            if (diffingActive && crModuleVersion.diffContent.length) {
                return UrlSchemeUtils.replaceUrlScheme(crModuleVersion.diffContent, window.location.origin);
            }

            return crModuleVersion.content;
        }

        if (currentPage % 3 === 1 || !crEditContent.diffContent.length || !diffingActive) {
            return crEditContent.moduleVersionContent;
        }

        return UrlSchemeUtils.replaceUrlScheme(crEditContent.diffContent, window.location.origin);
    };

    const handleMoreInfoClick = () => {
        setIsComplianceInfoModalOpen(true);
    };

    const handleFileUploadTypeError = (fileExtension: string) => {
        enqueueSnackbar(
            t('common.notifications.fileUploadTypeError.message', { fileExtension: fileExtension?.toUpperCase() }),
            {
                variant: 'warning',
            }
        );
    };

    return (
        <div className="module-version module-version-item">
            {isEditingCRContent &&
            crModuleVersion &&
            crEditContent &&
            isContentLoaded &&
            crEditContent.moduleVersionContent !== undefined ? (
                <>
                    <ComplianceInfoBox
                        dataTestId="workflow-complianceInfoBox"
                        complianceInfo={complianceInfo}
                        onMoreInfoClick={handleMoreInfoClick}
                    />

                    <FullContentEditor
                        froalaEditorRef={froalaEditorRef}
                        username={userInfo.preferred_username}
                        document={document}
                        moduleVersion={{
                            ...crModuleVersion,
                            content: crEditContent.moduleVersionContent,
                        }}
                        links={crEditContent.links}
                        fileList={fileList}
                        mediaUploadUrl={ApiConstants.uploadMedia().toString()}
                        moduleLinkTargets={moduleLinkTargets}
                        requestModuleLinkTargets={requestModuleLinkTargets}
                        onChange={handleCREditContentChange}
                        onLinkCreate={handleLinkCreate}
                        onLinkUpdate={handleLinkUpdate}
                        onLinkDelete={handleLinkDelete}
                        onFileUploadTypeError={handleFileUploadTypeError}
                    />
                    <div className="content-editor__actions">
                        <YonderButton variant="text" onClick={closeCREditContentEditor}>
                            {t('form.actions.cancel')}
                        </YonderButton>
                        <div className="grow" />
                        <YonderButton variant="text" onClick={saveCREditContent}>
                            {t('form.actions.save')}
                        </YonderButton>
                    </div>
                    <ComplianceInfoModal
                        open={isComplianceInfoModalOpen}
                        onClose={() => setIsComplianceInfoModalOpen(false)}
                        complianceInfo={complianceInfo.data}
                    />
                </>
            ) : (
                <>
                    {!isContentLoaded ? (
                        <div className="empty empty-root align-center">
                            <Spinner />
                        </div>
                    ) : !isDraftVersionAvailable && !crEditContent && !canEditCR ? (
                        <div className="empty empty-root align-center">
                            {t('workflow.changeRequest.errors.draftVersionNotAvailable')}
                        </div>
                    ) : draftContentError && !crEditContent ? (
                        <div className="empty empty-root align-center">
                            {draftContentError.message && draftContentError.message.length > 0
                                ? draftContentError.message
                                : t('workflow.changeRequest.errors.draftVersionNotLoaded')}
                        </div>
                    ) : !isDeleteCR && crModuleVersion ? (
                        <ModuleVersionContent
                            document={document}
                            moduleVersion={{
                                ...crModuleVersion,
                                content: setContent(),
                            }}
                            links={nextModule ? nextModule.links : []}
                            crInEditLockInfo={crInEditLockInfo}
                            showLockMessage={
                                Boolean(crInEditLockInfo) && canEditCR && isContentLoaded && !draftContentError
                            }
                        />
                    ) : (
                        <Empty
                            className="content empty"
                            variant="text"
                            text={
                                !isDeleteCR
                                    ? t('workflow.changeRequest.errors.noDraftVersion')
                                    : t('workflow.changeRequest.errors.noContentAvailable')
                            }
                        />
                    )}
                    <div className="module-version-item__view draft">{t('workflow.changeRequest.views.draft')}</div>
                    {canEditCR && isContentLoaded && !draftContentError && (
                        <FabButton size="small" onClick={startEditingCR} disabled={Boolean(crInEditLockInfo)}>
                            <Edit />
                        </FabButton>
                    )}
                </>
            )}
            {isEditingCRTitle && crEditTitle && crEditTitle.moduleVersionTitle !== undefined && (
                <YonderDialog
                    isOpen={true}
                    title={<Typography variant="h6">{t('workflow.changeRequest.editTitle.title')}</Typography>}
                    content={
                        <Grid item={true}>
                            <TextField
                                value={crEditTitle.moduleVersionTitle}
                                variant="outlined"
                                fullWidth={true}
                                onChange={handleCREditTitleChange}
                                label={t('workflow.changeRequest.editTitle.content')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    }
                    primaryAction={t('form.actions.save')}
                    secondaryAction={t('form.actions.cancel')}
                    onConfirm={saveCREditTitle}
                    onCancel={closeCREditTitleDialog}
                    onClose={closeCREditTitleDialog}
                />
            )}
        </div>
    );
};
