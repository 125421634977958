import { call, delay, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { IWorkflowAttachment, IWorkflowAttachmentDescription } from '@yonder-mind/ui-core';
import { NotificationState } from '../../../enums';
import { IWorkflowApi } from '../../../interfaces';
import { revisionActions } from '../revision';
import { crActions } from '../change-request';
import * as actions from './process-actions';

function mapSingleAttachment(attachmentDto: any): IWorkflowAttachment {
    let parsedDescription: IWorkflowAttachmentDescription;
    try {
        parsedDescription = JSON.parse(attachmentDto.description);
    } catch (e) {
        return undefined;
    }
    return {
        id: attachmentDto.id,
        name: attachmentDto.name,
        uploadDate: new Date(attachmentDto.createTime),
        user: parsedDescription.user,
        size: parsedDescription.fileSize,
    };
}

export function mapAttachments(apiResponse: any): IWorkflowAttachment[] {
    const merge = (arr: any) => [...[].concat(...arr)];
    return merge(Object.values(apiResponse))
        .map((it) => mapSingleAttachment(it))
        .filter((element) => element !== undefined);
}

function* requestAllMyTasks(api: IWorkflowApi): any {
    try {
        const tasks = yield call([api, api.getMyTasks]);
        yield put(actions.allMyTasksReceived(tasks));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestAllMyTasksOfProcess(
    api: IWorkflowApi,
    action: ActionType<typeof actions.myTasksOfProcessRequested>
): any {
    const { processInstanceId } = action.payload;

    try {
        const tasks = yield call([api, api.getProcessTasks], processInstanceId);
        yield put(actions.myTasksOfProcessReceived(processInstanceId, tasks));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestHistoryOfProcess(
    api: IWorkflowApi,
    action: ActionType<typeof actions.historiesOfProcessRequested>
): any {
    const { processInstanceId } = action.payload;

    try {
        const histories = yield call([api, api.getProcessHistories], processInstanceId);
        yield put(actions.historiesOfProcessReceived(processInstanceId, histories));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestTaskCompletion(api: IWorkflowApi, action: ActionType<typeof actions.completeTask>) {
    const { taskId, processInstanceId, data, documentOid, variant } = action.payload;
    try {
        yield call([api, api.completeTask], taskId, data);
        yield put(actions.taskCompleted(taskId, processInstanceId, data));

        yield put(actions.myTasksOfProcessRequested(processInstanceId));
        yield put(actions.historiesOfProcessRequested(processInstanceId));

        if (variant === 'change-request') {
            yield put(crActions.updateChangeRequestProcess({ changeRequestId: processInstanceId }));
        }

        if (variant === 'revision') {
            yield put(actions.updateProcess(processInstanceId));
            yield put(revisionActions.allRevisionsRequested());
            yield put(revisionActions.revisionHistoricRequested(documentOid, 'FINISHED'));
        }
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));

            if (variant === 'change-request' && (error as any).status === 500) {
                yield put(actions.myTasksOfProcessRequested(processInstanceId));
                yield put(actions.historiesOfProcessRequested(processInstanceId));
                yield put(crActions.updateChangeRequestProcess({ changeRequestId: processInstanceId }));
            }
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* updateProcess(api: IWorkflowApi, action: ActionType<typeof actions.updateProcess>): any {
    const { processInstanceId } = action.payload;

    try {
        yield delay(250);
        const activity = yield call([api, api.getProcessActivity], processInstanceId);

        if (activity) {
            yield put(actions.updatedProcess(processInstanceId, activity));
        }
        if (processInstanceId) {
            yield put(actions.requestVariablesGrouped(processInstanceId));
        }
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
        if (processInstanceId) {
            yield put(actions.requestVariablesGrouped(processInstanceId));
        }
    }
}

function* requestCommentPermission(
    api: IWorkflowApi,
    action: ActionType<typeof actions.canCommentOnProcessRequested>
): any {
    const { processInstanceId } = action.payload;

    try {
        const result = yield call([api, api.canCommentOnProcess], processInstanceId);
        yield put(actions.canCommentOnProcessReceived(processInstanceId, result));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        yield put(actions.canCommentOnProcessReceived(processInstanceId, false));
    }
}

function* requestCommentsOfProcess(
    api: IWorkflowApi,
    action: ActionType<typeof actions.commentsOfProcessRequested>
): any {
    const { processInstanceId } = action.payload;

    try {
        const comments = yield call([api, api.getCommentsOfProcess], processInstanceId);
        yield put(actions.commentsOfProcessReceived(processInstanceId, comments));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

// TODO: Remove once the comment can be sent via websocket
function* commentOnProcess(api: IWorkflowApi, action: ActionType<typeof actions.addCommentToProcessRequested>) {
    const { processInstanceId, message } = action.payload;

    try {
        // const comment =
        yield call([api, api.commentOnProcess], processInstanceId, message);
        // yield put(actions.addCommentToProcessConfirmationReceived(processInstanceId, comment));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* getCommentNotificationStatus(
    api: IWorkflowApi,
    action: ActionType<typeof actions.requestCommentNotificationState>
): any {
    const { processInstanceId } = action.payload;

    try {
        const enabled = yield call([api, api.getCommentNotificationStatus], processInstanceId);

        yield put(
            actions.receivedCommentNotificationState(
                processInstanceId,
                enabled ? NotificationState.ENABLED : NotificationState.DISABLED
            )
        );
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* enableCommentNotification(
    api: IWorkflowApi,
    action: ActionType<typeof actions.enableCommentNotificationsOfProcess>
) {
    const { processInstanceId } = action.payload;

    try {
        yield call([api, api.enabledCommentNotifications], processInstanceId);
        yield put(actions.enabledCommentNotificationsOfProcess(processInstanceId));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* disableCommentNotification(
    api: IWorkflowApi,
    action: ActionType<typeof actions.disableCommentNotificationsOfProcess>
) {
    const { processInstanceId } = action.payload;

    try {
        yield call([api, api.disabledCommentNotifications], processInstanceId);
        yield put(actions.disabledCommentNotificationsOfProcess(processInstanceId));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestAttachmentsOfProcess(
    api: IWorkflowApi,
    action: ActionType<typeof actions.attachmentsOfProcessRequested>
): any {
    const { processInstanceId } = action.payload;

    try {
        const attachments = yield call([api, api.getAttachmentsOfProcess], processInstanceId);
        const attachmentsMapped = mapAttachments(attachments);
        yield put(actions.attachmentsOfProcessReceived(processInstanceId, attachmentsMapped));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* uploadAttachment(api: IWorkflowApi, action: ActionType<typeof actions.uploadAttachment>): any {
    try {
        yield call([api, api.uploadAttachment], action.payload.processInstanceId, action.payload.attachment);
        yield put(actions.attachmentUploaded(action.payload.processInstanceId));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        yield put(actions.uploadAttachmentError(error));
    }
}

function* uploadAttachmentCompleted(action: ActionType<typeof actions.attachmentUploaded>) {
    try {
        yield put(actions.attachmentsOfProcessRequested(action.payload.processInstanceId));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* deleteAttachment(api: IWorkflowApi, action: ActionType<typeof actions.deleteAttachment>): any {
    try {
        yield call([api, api.deleteAttachment], action.payload.attachmentId);
        yield put(actions.attachmentsOfProcessRequested(action.payload.processInstanceId));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestProcessVariables(api: IWorkflowApi, action: ActionType<typeof actions.requestVariables>): any {
    const { processInstanceId } = action.payload;

    try {
        const variables = yield call([api, api.getProcessVariables], processInstanceId);
        yield put(actions.receivedVariables(processInstanceId, variables));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestProcessVariablesGrouped(
    api: IWorkflowApi,
    action: ActionType<typeof actions.requestVariablesGrouped>
): any {
    const { processInstanceId } = action.payload;

    try {
        const variables = yield call([api, api.getProcessVariablesGrouped], processInstanceId);
        yield put(actions.receivedVariablesGrouped(processInstanceId, variables));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* updateProcessVariables(api: IWorkflowApi, action: ActionType<typeof actions.updateVariables>) {
    const { processInstanceId, updates } = action.payload;

    try {
        yield call([api, api.updateVariablesOfProcess], processInstanceId, updates);
        yield put(actions.updatedVariables(processInstanceId, updates));
        yield put(crActions.changeRequestVariableUpdatesReceived(processInstanceId, updates));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

export function* processSagas(api: IWorkflowApi) {
    yield takeLatest(getType(actions.allMyTasksRequested), requestAllMyTasks, api);
    yield takeLatest(getType(actions.myTasksOfProcessRequested), requestAllMyTasksOfProcess, api);
    yield takeLatest(getType(actions.historiesOfProcessRequested), requestHistoryOfProcess, api);
    yield takeLatest(getType(actions.commentsOfProcessRequested), requestCommentsOfProcess, api);
    yield takeLatest(getType(actions.requestCommentNotificationState), getCommentNotificationStatus, api);
    yield takeLatest(getType(actions.enableCommentNotificationsOfProcess), enableCommentNotification, api);
    yield takeLatest(getType(actions.disableCommentNotificationsOfProcess), disableCommentNotification, api);
    yield takeLatest(getType(actions.addCommentToProcessRequested), commentOnProcess, api);
    yield takeLatest(getType(actions.attachmentsOfProcessRequested), requestAttachmentsOfProcess, api);
    yield takeLatest(getType(actions.uploadAttachment), uploadAttachment, api);
    yield takeLatest(getType(actions.deleteAttachment), deleteAttachment, api);
    yield takeLatest(getType(actions.attachmentUploaded), uploadAttachmentCompleted);
    yield takeLatest(getType(actions.completeTask), requestTaskCompletion, api);
    yield takeLatest(getType(actions.requestVariables), requestProcessVariables, api);
    yield takeLatest(getType(actions.requestVariablesGrouped), requestProcessVariablesGrouped, api);
    yield takeLatest(getType(actions.updateVariables), updateProcessVariables, api);
    yield takeLatest(getType(actions.updateProcess), updateProcess, api);
    yield takeEvery(getType(actions.canCommentOnProcessRequested), requestCommentPermission, api);
}
