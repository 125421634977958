import {
    FileContextMetadata,
    FilesApi,
    Rest,
    UpdateFileContextMetadata,
    UpdateFileRevisionMetadata,
} from '@yonder-mind/ui-core';
import { FileConstants } from './FilesConstants';
import dayjs from 'dayjs';

export class FilesRestApi implements FilesApi {
    async getFileList(): Promise<FileContextMetadata[]> {
        const effectivityEpoch = dayjs().toISOString();
        const response = await Rest.getRequest(FileConstants.getFileList(effectivityEpoch).toString());
        return response.json();
    }
    getFileContent(contextOid: string, notificationOid?: string): Promise<string> {
        return Promise.resolve(FileConstants.getFileContent(contextOid, notificationOid).toString());
    }

    async getMemoContent(contextOid: string): Promise<string> {
        const response = await Rest.getRequest(FileConstants.getFileContent(contextOid).toString());
        return response.text();
    }

    async uploadFile(file: FormData, key: string): Promise<FileContextMetadata> {
        try {
            const response = await Rest.postFormData(FileConstants.uploadFile().toString(), file);
            return response.json();
        } catch (e) {
            return { oid: key, tagIds: [], userIds: [], revisions: [] };
        }
    }

    async updateFileRevisionMetadata(updateRevisionMetadata: UpdateFileRevisionMetadata): Promise<FileContextMetadata> {
        const response = await Rest.patchRequest(
            FileConstants.updateFileRevisionMetadata(updateRevisionMetadata).toString(),
            updateRevisionMetadata.file,
            false
        );
        return response.json();
    }

    async updateFileContextMetadata(
        updateFileContextMetadata: UpdateFileContextMetadata
    ): Promise<FileContextMetadata> {
        const response = await Rest.patchRequest(
            FileConstants.updateFileContextMetadata(updateFileContextMetadata).toString()
        );
        return response.json();
    }

    async createNewRevision(file: FormData, key: string, fileIdToUpdate: string): Promise<FileContextMetadata> {
        try {
            const response = await Rest.postFormData(FileConstants.createNewRevision(fileIdToUpdate).toString(), file);
            return response.json();
        } catch (e) {
            return { oid: key, tagIds: [], userIds: [], revisions: [] };
        }
    }

    async deleteFile(fileContextOid: string): Promise<void> {
        await Rest.deleteRequest(FileConstants.deleteFile(fileContextOid).toString());
    }
}
