import dotenv from 'dotenv';
import { createRoot } from 'react-dom/client';
import { AppProvider, AuthService, history, IAuthApi, IAuthServiceApi } from '@yonder-mind/ui-core';
import { initI18next } from '@yonder-mind/ui-core/src/config/i18next';
import { RestAuthApi } from './app/api/rest-auth-api';
import { App } from './app/container/App';
import * as serviceWorker from './serviceWorker';
import './styles/app.scss';
import { setupStore } from './app/store';
import { registerFetchIntercept } from './app/utils/auth/FetchIntercept';

void initI18next();
dotenv.config();

const authApi: IAuthApi = new RestAuthApi();
const authService: IAuthServiceApi = new AuthService(authApi);
registerFetchIntercept(authService);

const { store, persistor } = setupStore(authApi, authService);

const root = createRoot(document.getElementById('root'));

root.render(
    <AppProvider context="web">
        <App store={store} history={history} persistor={persistor} />
    </AppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
