import React, { useState } from 'react';
import { Preset } from '../domain/types';
import { authSelector, Spinner, useApp, useHistory, useQuery, YonderDialog } from '@yonder-mind/ui-core';
import { DeleteOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface IProps {
    presets: Preset[];
    isLoading: boolean;
}
export const PresetsList: React.FC<IProps> = ({ presets, isLoading }) => {
    const { context } = useApp();
    const dispatch = useDispatch();
    const [presetOidToDelete, setPresetOidToDelete] = useState('');

    const { name: presetNameToDelete } = useSelectorUiWeb((state) => {
        return state.complianceReports.presets.find((p) => p.oid === presetOidToDelete) ?? { name: '' };
    });

    const hasComplianceShowUserRole = useSelectorUiWeb(authSelector.hasComplianceShowUserRole);

    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation();

    const { pushUrl } = useHistory();

    const query = useQuery();
    const presetId = query.get('preset');

    const openPreset = (preset: Preset) => {
        const hasUsersInPreset = preset.users.length;
        if (hasUsersInPreset && !hasComplianceShowUserRole) {
            enqueueSnackbar(t('complianceReports.preset.noUsersAccess'), { variant: 'error' });
            return;
        }
        pushUrl(`/compliance-reports/${preset.status.toLowerCase()}/`, [{ key: 'preset', value: preset.oid }]);
    };

    const handleDelete = (oid: string) => {
        if (presetId === oid) {
            enqueueSnackbar(`${t('complianceReports.preset.messages.warnings.deleteActive')}`, {
                variant: 'warning',
            });
        } else {
            setPresetOidToDelete(oid);
        }
    };

    const deleteCanceled = () => setPresetOidToDelete('');

    return isLoading ? (
        <Spinner />
    ) : (
        <div className="sidebar-presets">
            <div className="presets-title">
                <p>
                    <b>{t('complianceReports.preset.title')}</b>
                </p>
            </div>
            <YonderDialog
                isOpen={presetOidToDelete !== ''}
                title={t('complianceReports.preset.delete.title')}
                content={t('complianceReports.preset.delete.content', { presetNameToDelete })}
                primaryAction={t('form.actions.confirm')}
                secondaryAction={t('form.actions.cancel')}
                onConfirm={() => {
                    dispatch(
                        complianceReportsActions.deleteCompliancePresetRequested(presetOidToDelete, () =>
                            enqueueSnackbar(`${t('complianceReports.preset.messages.success.deleteSuccess')}`, {
                                variant: 'success',
                            })
                        )
                    );
                    deleteCanceled();
                }}
                onCancel={deleteCanceled}
                onClose={deleteCanceled}
            />
            {presets.length > 0 ? (
                <div className="preset-list-items">
                    <div className="preset-list-items-scroll-wrapper">
                        {presets.map((preset: Preset, key: number) => {
                            return (
                                <div
                                    className={`preset-list-item ${presetId === preset.oid ? 'active' : ''}`}
                                    key={key}
                                >
                                    <div onClick={() => openPreset(preset)}>
                                        <p>{preset.name}</p>
                                        <p className="preset-status">
                                            {t('complianceReports.sidebar.' + preset.status.toLowerCase())}
                                        </p>
                                    </div>

                                    <div className={`delete-preset ${context === 'mobile' ? 'mobile' : ''}`}>
                                        <IconButton
                                            className="delete-button"
                                            onClick={() => handleDelete(preset.oid)}
                                            disableRipple={true}
                                            size="small"
                                        >
                                            <DeleteOutlined />
                                        </IconButton>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <p>{t('complianceReports.preset.noPresets')}</p>
            )}
        </div>
    );
};
