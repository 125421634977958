import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import moment from 'moment';
import { IWorkflowActivity, IWorkflowRevisionHistoric, WorkflowUtils } from '@yonder-mind/ui-core';
import { IWorkflowApi } from '../../../interfaces';
import { processActions } from '../process';
import { revisionSelector } from './revision-selector';
import * as actions from './revision-actions';

function* requestRevisionByDocumentId(
    api: IWorkflowApi,
    action: ActionType<typeof actions.revisionForDocumentRequested>
): any {
    try {
        const revision = yield call([api, api.getRevisions], { documentId: action.payload });
        yield put(actions.revisionForDocumentReceived(revision));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestAllRevisions(api: IWorkflowApi): any {
    try {
        const revisions = yield call([api, api.getRevisions]);
        yield put(actions.allRevisionsReceived(revisions));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestRevisionHistoryOfDocument(
    api: IWorkflowApi,
    action: ActionType<typeof actions.revisionHistoryOfDocumentRequested>
): any {
    try {
        const history = yield call([api, api.getRevisionHistory], action.payload);
        yield put(actions.revisionHistoryOfDocumentReceived(action.payload, history));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestRevisionHistoricOfDocument(
    api: IWorkflowApi,
    action: ActionType<typeof actions.revisionHistoricRequested>
): any {
    try {
        const { documentId, state } = action.payload;
        const historics = yield call([api, api.getRevisionHistoric], documentId, state as string);
        historics.sort((a: IWorkflowRevisionHistoric, b: IWorkflowRevisionHistoric) =>
            moment(b.startTime).diff(moment(a.startTime))
        );
        yield put(actions.revisionHistoricReceived(documentId, historics[0]));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestNewRevisionFormFields(
    api: IWorkflowApi,
    action: ActionType<typeof actions.newRevisionFormFieldsRequested>
): any {
    const { contextType, contextSubType } = action.payload;
    try {
        const fields = yield call([api, api.getNewRevisionForm], contextType, contextSubType);
        yield put(actions.newRevisionFormFieldsReceived(fields));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* addNewRevision(api: IWorkflowApi, action: ActionType<typeof actions.startNewRevisionRequested>): any {
    const { contextType, data } = action.payload;
    try {
        const revision = yield call([api, api.startNewRevision], contextType, data);
        yield put(actions.startNewRevisionConfirmationReceived(revision));
        yield put(actions.allRevisionsRequested());
        yield put(actions.revisionHistoryOfDocumentRequested(WorkflowUtils.getContextVersionOid(data)));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* updateRevision(action: ActionType<typeof processActions.updatedProcess>) {
    const { processInstanceId, activity } = action.payload;

    try {
        const allRevisions: { [processInstanceId: string]: IWorkflowActivity } = yield select(
            revisionSelector.getRevisionsByProcessId
        );
        const revision = allRevisions[processInstanceId];

        if (revision) {
            yield put(actions.revisionUpdatesReceived(processInstanceId, activity));
        }
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

export function* revisionSagas(api: IWorkflowApi) {
    yield takeLatest(getType(actions.allRevisionsRequested), requestAllRevisions, api);
    yield takeLatest(getType(actions.revisionHistoricRequested), requestRevisionHistoricOfDocument, api);
    yield takeLatest(getType(actions.revisionForDocumentRequested), requestRevisionByDocumentId, api);
    yield takeLatest(getType(actions.revisionHistoryOfDocumentRequested), requestRevisionHistoryOfDocument, api);
    yield takeEvery(getType(actions.newRevisionFormFieldsRequested), requestNewRevisionFormFields, api);
    yield takeEvery(getType(actions.startNewRevisionRequested), addNewRevision, api);
    yield takeEvery(getType(processActions.updatedProcess), updateRevision);
}
